import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Flex from '../../components/flex';
import CaseStudyNav from "../../components/caseStudyNav";
import CaseStudyOverview from "../../components/caseStudyOverview";
import Hr from "../../components/hr";
import Research from "../../components/research";
import Column from "../../components/column";
import CaseStudySection from "../../components/caseStudySection";

import userImage1 from '../../images/flexjobs-user1.jpg';
import userImage2 from '../../images/flexjobs-user2.jpg';
import userImage3 from '../../images/flexjobs-user3.jpg';
import UserCard from "../../components/userCard";
import Quote from "../../components/quote";
import DesignSection from "../../components/designSection";

const CooksCatalog = ({
  uri,
}) => {
  const {
    banner: { childImageSharp: { fluid: banner } },
    taskAnalysis: { childImageSharp: { fluid: taskAnalysis } },
    tableFindings: { childImageSharp: { fluid: tableFindings } },
    findingsImage: { childImageSharp: { fluid: findingsImage } },
    userPersona: { childImageSharp: { fluid: userPersona } },
    wireFramePrototyping: { childImageSharp: { fluid: wireFramePrototyping } },
    design1: { childImageSharp: { fluid: design1 } },
    design2: { childImageSharp: { fluid: design2 } },
    design3: { childImageSharp: { fluid: design3 } },
  } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "cooks-catalog-header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      taskAnalysis: file(relativePath: { eq: "casestudy-cc-image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tableFindings: file(relativePath: { eq: "casestudy-flexjobs-image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      findingsImage: file(relativePath: { eq: "casestudy-flexjobs-image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      userPersona: file(relativePath: { eq: "cc-persona.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wireFramePrototyping: file(relativePath: { eq: "casestudy-cc-image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design1: file(relativePath: { eq: "casestudy-cc-image5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design2: file(relativePath: { eq: "casestudy-cc-image4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design3: file(relativePath: { eq: "casestudy-flexjobs-image7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="The Cook's Catalog UX Case Study" />
      <Flex
        flexDirection="column"
        alignItems="center"
      >
        <Img fluid={banner} style={{ width: '100%', }} />
        <Flex
          flexDirection="column"
          alignItems="center"
          width={1}
          px={[20, null, 0]}
        >
          <CaseStudyNav uri={uri} />
          <CaseStudyOverview
            context="A UX design side project of my own creation"
            team="Solo, under mentorship"
            role="UX/UI Designer"
            timeline="2 Week sprint"
            deliverables="Survey building and reporting, user persona, wireframes, interactive prototypes, high fidelity designs"
            title="THE COOK'S CATALOG"
            type="A UX Case Study + UI Design"
            description="The Cook’s Catalog is a recipe aggregator, catalog and ingredient-based search engine. It helps busy home cooks find meals they can make with the ingredients they have on hand."
          />
          <Hr />
          <Research
            challenges={(
              <>
                <Flex>• Relevant recipes are difficult to find online</Flex>
                <Flex>• Recipes include unsuitable ingredients</Flex>
                <Flex>• Cooking blogs are ad-heavy and personalized</Flex>
              </>
            )}
            solutions={(
              <div>
                The idea was to build a platform to aggregate recipes into a simple structure <strong>without ads or personal stories and</strong> create a way for users to search recipes based on <strong>specific ingredients </strong>they have on hand and by <strong>dietary needs.</strong>These challenges were <strong>validated as real issues by this user research.</strong>
              </div>
            )}
          />
          <CaseStudySection>
            <h4>Methodology</h4>
            <Flex>Using Google Forms, Reddit, and my own network, I built and shared a survey to learn more about home cooks. I aimed to discover their wants, needs, and frustrations with the current landscape of online recipe tools. </Flex>
          </CaseStudySection>
          <CaseStudySection>
            <h4>Key Findings</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                <Flex><strong>Top obstacles encountered when searching online:</strong></Flex>
                <Flex>• Require ingredients I don’t have</Flex>
                <Flex>• Too many ads on websites/blogs</Flex>
                <Flex>• Don’t know exactly what to look for</Flex>
              </Column>
              <Column>
                <Flex><strong>About the respondents:</strong></Flex>
                <Flex>• Mostly millenial couples with no children</Flex>
                <Flex>• Mostly use phone to follow along with recipes</Flex>
              </Column>
              <Column last>
                <Flex></Flex>
                <Flex>• Dietary needs are very important to be able to search/filter by</Flex>
                <Flex>• Most use Pinterest currently for finding and saving recipes</Flex>
              </Column>
            </Flex>
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={taskAnalysis} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection>
            <Flex>Read all of the survey responses <a href="https://docs.google.com/forms/d/13O2YtXym37aQhOCDFXs9DjSAn0NUFYvJY5H2d6sIZ_Q/edit#responses">here</a> (outside link). Using information gleaned from the survey, I was able to build two user personas based on real data. One persona is a single man, cooking oftern and for one. The persona featured below is a woman who is part of a couple that splits cooking responsibilities. </Flex>
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="empathize">Empathize</h2>
            <h4>Users</h4>
            <Img fluid={userPersona} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="ideate">Ideate</h2>
            <h4>Action Plan</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                <Flex fontWeight="bold">Create a web platform</Flex>
                <div>
                  to <strong>aggregate recipe content.</strong> Make it easy for users to search for inspiration and ideas <strong>relevant to their unique needs.</strong>
                </div>
              </Column>
              <Column>
                <Flex fontWeight="bold">Include a robust filtering component</Flex>
                <div>
                  to the search. Allow users to curate their own recipe feeds <strong>based on specific criteria.</strong>
                </div>
              </Column>
              <Column last>
                <Flex fontWeight="bold">Build a product around a central goal</Flex>
                <div>
                  of searching for recipes <strong>based on specific ingredients.</strong> Only display <strong>de-personalized recipe content.</strong>
                </div>
              </Column>
            </Flex>
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={wireFramePrototyping} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection
            mb={0}
          >
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="design">Design</h2>
     <h4>Overview</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                <Flex><strong>• Seamless cross-device experience</strong></Flex>
              </Column>
              <Column>
                <Flex><strong>• Instagram + Pinterest + Ecommerce</strong></Flex>
              </Column>
              <Column last>
                <Flex><strong>• No inline display or affiliate ads</strong></Flex>
              </Column>
            </Flex>
   </CaseStudySection>
          <CaseStudySection>
            <Img fluid={design1} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={design2} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
         
        </Flex>
      </Flex>
    </Layout>
  );
};

export default CooksCatalog;
